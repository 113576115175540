/* eslint-disable complexity */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { getShareLinkToken } from 'pages/App/utils/shareLink';
import { BetaLogo } from 'components/BetaLogo';
import HeaderLogo from 'components/HeaderLogo';
import Header from '../../../../components/Header';
import Nav from '../../../../components/Nav';
import MobileNav from '../../../../styles/components/MobileNav';
import { PATHS } from '../../../../utils/constants';
import { getPrimaryNavItems, getSecondaryNavItems, getSideNavbarStatus } from '../../utils';
import { NullHeaderLogo } from './sideNavbarStyles';

const SideNavbar = ({
  externalShareData,
  hasWhiteLabelingNavbar,
  userData,
  isOperationsVisibleToUser,
  isBetaFeatureEnabled
}) => {
  const location = useLocation();
  const { pathname } = location;

  const navRef = useRef();

  const publicPageWithDisabledNavbar =
    (pathname && pathname.startsWith(PATHS.EXPLORE_SHARE)) || getShareLinkToken();
  const hasSideNavbar = getSideNavbarStatus(pathname);

  if (!hasSideNavbar) {
    return null;
  }

  const Logo = (showBetaLogo) => {
    const accountId = userData?.accountId;
    const brandingStatus = externalShareData?.site?.account?.brandingStatus;

    const isFetchingLogoData = accountId === '' && brandingStatus === undefined;

    if (isFetchingLogoData || hasWhiteLabelingNavbar) {
      return showBetaLogo ? null : <NullHeaderLogo />;
    }

    if (showBetaLogo) return <BetaLogo />;
    return <HeaderLogo to="/" />;
  };

  const primaryNavItems = getPrimaryNavItems(isOperationsVisibleToUser, isBetaFeatureEnabled);
  const secondaryNavItems = getSecondaryNavItems();

  return (
    <>
      <Header hasWhiteLabelingNavbar={hasWhiteLabelingNavbar} ref={navRef}>
        <Logo showBetaLogo={isBetaFeatureEnabled} />
        <Nav
          items={primaryNavItems}
          location={location}
          modifiers={{ primary: true, hideOnSmall: true }}
          disabled={publicPageWithDisabledNavbar}
        />
        <Nav
          items={secondaryNavItems}
          location={location}
          modifiers={{ secondary: true }}
          disabled={publicPageWithDisabledNavbar}
        />
      </Header>

      <MobileNav>
        <Nav
          items={primaryNavItems}
          location={location}
          modifiers={{ primary: true }}
          disabled={publicPageWithDisabledNavbar}
        />
      </MobileNav>
    </>
  );
};

SideNavbar.propTypes = {
  hasWhiteLabelingNavbar: PropTypes.bool,
  isBetaFeatureEnabled: PropTypes.bool.isRequired,
  isOperationsVisibleToUser: PropTypes.bool.isRequired,
  externalShareData: PropTypes.shape(),
  userData: PropTypes.shape()
};

SideNavbar.defaultProps = {
  hasWhiteLabelingNavbar: false,
  externalShareData: null,
  userData: null
};

export default SideNavbar;
