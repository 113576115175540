// Beta paths
export const EXPLORE_ROOT = '/explore';
export const PROJECT_ROOT = `/project`;
export const EXPLORE_ERROR = '/error/:errorCode';
export const EXPLORE_EMPTY_RESOURCE = '/empty-resource/:resourceType';

export const DATASETS_ROOT = '/datasets';
export const DATASETS_FOLDERS = `${DATASETS_ROOT}/folders`;
export const DATASETS_FOLDER_FILES = `${DATASETS_FOLDERS}/:id/files`;
export const DATASETS_ZIP_DOWNLOAD = `${DATASETS_ROOT}/download/zips/:zipRequestId`;

export const DATASETS_BETA_COMPAT = `/beta${DATASETS_ROOT}`;
export const DATASETS_BETA_COMPAT_FOLDER_FILES = `${DATASETS_BETA_COMPAT}/folders/:id/files`;
export const DATASETS_BETA_COMPAT_FOLDERS = `${DATASETS_BETA_COMPAT}/folders`;

export const MANAGE_ROOT = '/manage';
export const MANAGE_BRANDING = `${MANAGE_ROOT}/branding`;
export const MANAGE_GROUPS = `${MANAGE_ROOT}/groups`;
export const MANAGE_GROUPS_CREATE_DETAILS = `${MANAGE_GROUPS}/details/create`;
export const MANAGE_GROUPS_CREATE_PERMISSIONS = `${MANAGE_GROUPS}/permissions/create`;
export const MANAGE_GROUPS_CREATE_ROLES = `${MANAGE_GROUPS}/roles/create`;
export const MANAGE_GROUPS_CREATE_USERS = `${MANAGE_GROUPS}/users/create`;
export const MANAGE_GROUPS_DETAILS = `${MANAGE_GROUPS}/:id/details`;
export const MANAGE_GROUPS_PERMISSIONS = `${MANAGE_GROUPS}/:id/permissions`;
export const MANAGE_GROUPS_ROLES = `${MANAGE_GROUPS}/:id/roles`;
export const MANAGE_GROUPS_USERS = `${MANAGE_GROUPS}/:id/users`;
export const MANAGE_ROLES = `${MANAGE_ROOT}/roles`;
export const MANAGE_ROLES_CREATE_DETAILS = `${MANAGE_ROLES}/details/create`;
export const MANAGE_ROLES_CREATE_PERMISSIONS = `${MANAGE_ROLES}/permissions/create`;
export const MANAGE_ROLES_DETAILS = `${MANAGE_ROLES}/:id/details`;
export const MANAGE_ROLES_GROUPS = `${MANAGE_ROLES}/:id/groups`;
export const MANAGE_ROLES_PERMISSIONS = `${MANAGE_ROLES}/:id/permissions`;
export const MANAGE_ROLES_USERS = `${MANAGE_ROLES}/:id/users`;
export const MANAGE_SUBSCRIPTION = `${MANAGE_ROOT}/subscription`;
export const MANAGE_TEMPLATES = `${MANAGE_ROOT}/templates`;
export const MANAGE_TEMPLATES_CREATE = `${MANAGE_TEMPLATES}/create`;
export const MANAGE_TEMPLATES_DETAILS = `${MANAGE_TEMPLATES}/:id/details`;
export const MANAGE_TEMPLATES_EDIT = `${MANAGE_TEMPLATES}/:id/edit`;
export const MANAGE_UNAUTHORIZED = `${MANAGE_ROOT}/unauthorized`;
export const MANAGE_USERS = `${MANAGE_ROOT}/users`;
export const MANAGE_USERS_DETAILS = `${MANAGE_USERS}/:id/details`;
export const MANAGE_USERS_GROUPS = `${MANAGE_USERS}/:id/groups`;
export const MANAGE_USERS_PERMISSIONS = `${MANAGE_USERS}/:id/permissions`;
export const MANAGE_USERS_ROLES = `${MANAGE_USERS}/:id/roles`;

export const REPORTS_ROOT = '/reports';
export const REPORTS_VIEW = `${REPORTS_ROOT}/:id/view`;

export const PATHS = {
  ACCOUNT: '/account',
  AUTH: '/auth',
  BRANDING: '/manage/branding', // NOTE: deprecated
  CONFIRMATION: '/confirmation',
  DATASETS: '/datasets',
  DEMO: '/demo',
  EXPLORE_SHARE: '/explore/share', // NOTE: depracted
  EXPLORE: '/explore',
  FORGOT_PASSWORD: '/forgot-password',
  INVITATION: '/invitation',
  LOGIN: '/login',
  MANAGE: '/manage',
  OPERATIONS: '/operations',
  REPORTS: '/reports',
  SET_REGION: '/set-region',
  ROOT: '/dashboard', // NOTE: deprecated
  SITES: '/manage/sites', // NOTE: deprecated
  USERS: '/manage/users', // NOTE: deprecated

  EXPLORE_BETA_COMPAT: `/beta${EXPLORE_ROOT}`, // for backward-compatibility

  EXPLORE_BETA: EXPLORE_ROOT,
  EXPLORE_BETA_PROJECT: PROJECT_ROOT,
  EXPLORE_BETA_ERROR: EXPLORE_ERROR,
  EXPLORE_BETA_EMPTY_RESOURCE: EXPLORE_EMPTY_RESOURCE,

  DATASETS_BETA_COMPAT,
  DATASETS_BETA_COMPAT_FOLDER_FILES,
  DATASETS_BETA_COMPAT_FOLDERS,

  DATASETS_BETA: DATASETS_ROOT,
  DATASETS_FOLDER_FILES,
  DATASETS_FOLDERS,
  DATASETS_ZIP_DOWNLOAD,

  MANAGE_BETA: MANAGE_ROOT,
  MANAGE_BRANDING,
  MANAGE_GROUPS,
  MANAGE_GROUPS_CREATE_DETAILS,
  MANAGE_GROUPS_CREATE_PERMISSIONS,
  MANAGE_GROUPS_CREATE_ROLES,
  MANAGE_GROUPS_CREATE_USERS,
  MANAGE_GROUPS_DETAILS,
  MANAGE_GROUPS_PERMISSIONS,
  MANAGE_GROUPS_ROLES,
  MANAGE_GROUPS_USERS,
  MANAGE_ROLES,
  MANAGE_ROLES_CREATE_DETAILS,
  MANAGE_ROLES_CREATE_PERMISSIONS,
  MANAGE_ROLES_DETAILS,
  MANAGE_ROLES_GROUPS,
  MANAGE_ROLES_PERMISSIONS,
  MANAGE_ROLES_USERS,
  MANAGE_SUBSCRIPTION,
  MANAGE_TEMPLATES,
  MANAGE_TEMPLATES_CREATE,
  MANAGE_TEMPLATES_DETAILS,
  MANAGE_TEMPLATES_EDIT,
  MANAGE_UNAUTHORIZED,
  MANAGE_USERS,
  MANAGE_USERS_DETAILS,
  MANAGE_USERS_GROUPS,
  MANAGE_USERS_PERMISSIONS,
  MANAGE_USERS_ROLES,

  REPORTS_BETA: REPORTS_ROOT,
  REPORTS_VIEW
};

export const CATEGORY_LAYER = {
  DEFECT: 'Defects',
  INTEREST: 'Objects of interest'
};

export const LOGO_TYPES = {
  REPORT_LOGO: 'report',
  SHARED_SITE_LOGO: 'share-site',
  BANNER_LOGO: 'banner'
};

export const LOCAL_STORAGES = {
  MEASUREMENT_SYSTEM_TYPE: 'measurementSystemType'
};

export const MEASUREMENT_SYSTEMS = {
  METRIC: 'METRIC',
  IMPERIAL: 'IMPERIAL',
  US_IMPERIAL: 'US_IMPERIAL'
};
