import { useQuery } from '@apollo/client';

import { useHandleInvalidToken } from '../../../hooks';
import { BRANDING_PREVIEW_PAGE_QUERY } from './queries';

const useFetchData = () => {
  const { checkInvalidTokenError } = useHandleInvalidToken();

  const {
    data: { accountByContext: account } = { accountByContext: { id: '' } },
    loading: isFetching,
    refetch: fetchAccountData,
    error
  } = useQuery(BRANDING_PREVIEW_PAGE_QUERY, {
    fetchPolicy: 'network',
    onError: (e) => {
      checkInvalidTokenError(e);
    }
  });

  return { account, fetchAccountData, isFetching, error };
};

export default useFetchData;
