// TODO-JONEL: Revert changes once accounts are migrated to use application credential
// eslint-disable-next-line import/no-unresolved
import { Root } from '@skand/webapp-manage';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { useSplitConfig } from 'splitConfig';
import { BetaReset } from 'utils/betaReset';
// import { PATHS } from 'utils/constants';
import { setSentryUser } from 'utils/sentry';

export const SetPasswordBeta = () => {
  const { setKeyAndCookie } = useSplitConfig();
  const history = useHistory();

  const onLogIn = ({ accountId, userEmail }) => {
    setKeyAndCookie({ userEmail, accountId });
    setSentryUser({ userEmail, accountId });
    // window.location.replace(PATHS.SET_REGION);
    history.push('/');
  };

  return (
    <>
      <BetaReset />
      <Helmet>
        <title>Set your password</title>
      </Helmet>

      <Root onLogIn={onLogIn} />
    </>
  );
};
